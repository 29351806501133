<template>
  <div class="content w-full p-5 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
    <loading
      v-model:active="isLoading"
      :is-full-page="fullPage"
      color="#00257A"
    ></loading>

    <div>
      <div class="rounded-lg p-5">
        <div class="text-blue-700 font-bold text-2xl mb-3 text-center">
          ประกาศรายชื่อผู้โชคดี
        </div>
        <div class="mt-5"></div>
        <!-- -->
        <div>
          <div>
            <div class="text-gray-700 font-bold mb-2">
              ผู้โชคดีประจำครั้งที่
            </div>
            <div class="relative flex w-full flex-wrap items-stretch mb-3">
              <span
                class="z-10 h-full absolute text-center text-blue-700 absolute bg-transparent rounded items-center justify-center w-8 pl-3 py-3"
              >
              </span>

              <select
                v-model="winNo"
                placeholder="เลือกวันประกาศผล"
                @change="onWinNoChange"
                class="px-3 py-3 relative bg-white bg-white rounded-2xl border border-gray-300 outline-none focus:outline-none focus:ring w-full pl-5"
              >
                <option value="0">เลือกวันประกาศผล</option>
                <option
                  v-for="(w, index) in winNoList"
                  :value="w.WD_ID"
                  :key="index"
                >
                  ครั้งที่ {{ w.WD_NO }} : วันที่ {{ w.WD_DATE_TH }}
                </option>
              </select>
            </div>
            <!-- -->
          </div>
        </div>
        <div v-if="winDateData !== null">
          <div v-if="winDateData.WD_HAS_WINNER">
            <div class="text-center mt-10 bg-white rounded-2xl p-5">
              <img src="../assets/images/reward.png" class="mx-auto" />
              <span class="text-xl font-bold text-blue-900"
                >รางวัล ทองคำแท่งน้ำหนัก 1 สลึง<br />มูลค่า 8,300 บาท</span
              >
              <div class="text-sm py-3">
                อ้างอิงราคาทองคำ ณ วันที่ 18 มิถุนายน 2566
              </div>
            </div>

            <div v-for="(w, index) in winDateData.WD_DATA" :key="index">
              <div class="rounded-lg mt-3">
                <div class="text-blue-700 text-center px-5">
                  <div></div>
                </div>
                <img :src="w.reward.RW_PICTURE" class="mt-3 mx-auto" />
                <div class="text-center text-sm mt-5 px-3">
                  ผู้โชคดีจะได้รับการติดต่อจากเจ้าหน้าที่ตามเบอร์โทรฯ
                  ที่ลงทะเบียนไว้
                  เพื่อยืนยันสิทธิ์และดำเนินการเตรียมเอกสารรับรางวัล
                </div>
                <div v-for="(p, index) in w.winner" :key="index">
                  <div
                    class="mt-5 bg-gradient-to-r from-blue-700 to-blue-900 rounded-2xl p-5 text-white text-center"
                  >
                    <div class="text-xl mb-5">
                      <span class="font-bold text-2xl"
                        >ผู้โชคดีครั้งที่ {{ winDateData.WD_NO }}</span
                      ><br />ประจำวันที่
                      {{ winDateData.WD_DATE_TH }}
                    </div>
                    <div
                      class="flex bg-white text-black rounded-2xl p-2 shadow-xl"
                    >
                      <div class="w-1/2">
                        {{ p.WC_CUS_FNAME }} {{ p.WC_CUS_LNAME }}
                      </div>
                      <div class="w-1/2 text-center">
                        {{ p.WC_CUS_MOBILE_HIDDEN }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!winDateData.WD_HAS_WINNER" class="py-5">
            <div class="text-center mt-10">
              <div
                class="text-blue-700 font-bold text-2xl pt-5 mb-1 text-center"
              >
                ขออภัยค่ะ
              </div>
              <h1 class="text-lg font-extrabold">
                ยังไม่ประกาศรายชื่อผู้โชคดี
              </h1>
              <h2>
                ครั้งที่ {{ winDateData.WD_NO }} (ประจำวันที่
                {{ winDateData.WD_DATE_TH }})
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped></style>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Service from "../service/service";
export default {
  name: "Winner",
  data() {
    return {
      isLoading: true,
      fullPage: true,
      winType: "0",
      winNo: "0",
      winNoList: null,
      winTypeOk: false,
      winDateData: null,
    };
  },
  components: {
    Loading,
  },
  service: null,
  created() {
    this.service = new Service();
  },
  async mounted() {
    this.isLoading = true;
    this.onWinTypeChange();
    this.isLoading = false;
  },
  methods: {
    onWinTypeChange() {
      this.winNoList = null;
      this.winNo = "0";
      this.winDateData = null;

      this.service.getWinnerDate(this.winType).then((data) => {
        this.winNoList = data.data;
      });
    },
    onWinNoChange() {
      this.winDateData = null;

      this.winNoList.forEach((e) => {
        //console.log(e.WD_ID, this.winNo);
        if (e.WD_ID === this.winNo) {
          this.winDateData = e;
        }
      });
      // console.log(this.winDateData);
    },
  },
};
</script>
