<template>
  <div>
    <div class="content w-full p-5 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#00257A"
      ></loading>

      <div class="">
        <div class="p-10">
          <div class="flex flex-col text-center">
            <img
              src="../assets/images/check.png"
              alt=""
              class="rounded-full h-32 w-32 mb-5 mx-auto"
            />

            <div class="text-xl text-black"></div>

            <div class="text-3xl text-blue-900 font-bold my-5">
              ส่งใบเสร็จสำเร็จ!
            </div>

            <div>
              ขอบคุณที่ร่วมกิจกรรมกับเรา<br />ระบบกำลังดำเนินการตรวจสอบใบเสร็จ
            </div>
          </div>
        </div>
      </div>
      <div class="flex mx-auto mt-2">
        <button
          @click="goTo('/submit')"
          class="w-1/2 text-xl font-bold rounded px-3 py-2 mr-1 rounded-lg border-b-4 border-l-2 shadow-lg border-blue-800 bg-gradient-to-r from-blue-800 to-blue-700 text-white"
        >
          ส่งใบเสร็จ
        </button>
        <button
          @click="goTo('/')"
          class="w-1/2 text-xl font-bold rounded px-3 py-2 ml-1 rounded-lg border-b-4 border-l-2 shadow-lg bg-gray-300 border-gray-400 text-gray-500"
        >
          กลับหน้าหลัก
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import liff from "@line/liff";
import configs from "../config";
import router from "../router";
export default {
  name: "Success",
  props: ["shop"],
  data() {
    return {
      userLineId: null,
      userLineDisplayName: null,
      userLinePictureUrl: null,
      userIsRegister: false,
      custInfo: null,
      isLoading: true,
      fullPage: true,
      submitBuyShop: this.shop,
      configs,
    };
  },
  components: {
    Loading,
  },
  service: null,
  created() {},

  async mounted() {
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      this.isLoading = true;
      const profile = await liff.getProfile();
      this.userLineId = profile.userId;
      this.userLineDisplayName = profile.displayName;
      this.userLinePictureUrl = profile.pictureUrl;

      this.isLoading = false;
    }
  },
  methods: {
    goTo(r) {
      router.push(r);
    },
    liffCloseWindow() {
      liff.closeWindow();
    },
  },
};
</script>
