<template>
  <div class="content w-full p-5 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
    <div class="p-1">
      <div class="rounded-lg p-5" style="min-height: 300px">
        <div>
          <!---->
          <div>
            <div class="">
              <div class="rounded-lg m-2 text-center">
                <img src="../assets/images/reward.png" class="mx-auto" />
                <span class="text-xl font-bold text-blue-900"
                  >รางวัล ทองคำแท่งน้ำหนัก 1 สลึง<br />มูลค่า 8,300 บาท</span
                >
                <div class="text-sm py-3">
                  อ้างอิงราคาทองคำ ณ วันที่ 18 มิถุนายน 2566
                </div>

                <span class="text-xl font-bold"
                  >จำนวน 75 รางวัล<br />มูลค่ารางวัลทั้งสิ้น 622,500 บาท</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {};
  },

  methods: {},
};
</script>
