<template>
  <div>
    <div class="content w-full p-5 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#00257A"
      ></loading>

      <div
        class="bg-gradient-to-b from-blue-700 via-white to-blue-900 rounded-lg shadow-lg p-1"
      >
        <div class="bg-white rounded-lg p-5">
          <div>
            <!---->
            <div>
              <div class="">
                <div class="rounded-lg m-2">
                  <div
                    class="text-blue-700 font-bold text-3xl pt-15 mb-3 text-center"
                  >
                    ขออภัยค่ะ
                  </div>
                  <div v-html="messageTxt" class="text-center"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "../service/service";

export default {
  name: "Off",
  props: ["state"],
  data() {
    return { messageTxt: "" };
  },
  service: null,
  created() {
    this.service = new Service();
  },
  async mounted() {
    this.service.getCampaignInfo().then((data) => {
      if (data.campaignInfo.C_ONLINE !== 1) {
        if (data.campaiggInfo.C_ONLINE === -1) {
          this.messageTxt = "กิจกรรมยังไม่เริ่ม<br />กรุณากลับมาใหม่ภายหลังค่ะ";
        } else {
          this.messageTxt =
            "กิจกรรมสิ้นสุดแล้ว<br />กรุณาติดตามข่าวประชาสัมพันธ์ค่ะ";
        }
      } else {
        this.messageTxt = "กรุณาปิดหน้าต่างและกดเข้าร่วมกิจกรรมจากเมนู";
      }
    });
  },
};
</script>
